import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { Filters } from '../Filters';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
//import * as XLSX from 'xlsx';
//import { saveAs } from 'file-saver';

@Component({
  selector: 'app-facility-summary',
  templateUrl: './facility-summary.component.html',
  styleUrls: ['./facility-summary.component.css']
})
export class FacilitySummaryComponent implements OnInit {
  metrics: any;
  details: any;
  opefficiency: string;
  filters: Filters;

  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
    this.LoadData();
  }

  OnFilter(e: Filters) {
    this.filters = e;
    this.LoadData();
  }

  LoadData() {
    this.opefficiency = this.filters.operationalefficiency;
    this.metrics = undefined;
    this.details = undefined;
    this.commonService.GetFacilitySummary(this.filters.facility, this.filters.month, this.filters.year, this.filters.operationalefficiency).subscribe(d => {
      this.metrics = d["FacilitySummaryMetrics"];
      this.details = d["FacilitySummaryDetails"];
    })
  }

  OnDownload(e: Filters) {
    this.filters = e;
    if (this.metrics != undefined) {
      let fileName = "facility_summary_" + this.filters.facility + "_" + this.filters.month + "_" + this.filters.year + "_" + this.filters.operationalefficiency + "_" + new Date().getTime() + ".pdf";

      const data = document.getElementById('MyDIv');
      html2canvas(data!).then(canvas => {
        const imgWidth = 208;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        const contentDataURL = canvas.toDataURL('image/png');
        const pdf = new jsPDF.jsPDF('p', 'mm', 'a4'); // A4 size page of PDF

        // Add the title
        let pdfTitle = "facility summary " + this.filters.facility + " " + this.filters.month + " " + this.filters.year + " " + this.filters.operationalefficiency;
        const position = 0;

        pdf.setProperties({
          title: pdfTitle
        });

        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        pdf.save(fileName); // Save the generated PDF
      });
    }
  }

  //OnDownload(e: Filters) {
  //  this.filters = e;
  //  if (this.metrics != undefined) {
  //    /*let fileName = "facility_summary_" + this.filters.facility + "_" + this.filters.month + "_" + this.filters.year + "_" + this.filters.operationalefficiency + ".xlsx";*/
  //    /* pass here the table id */
  //    /*const ws1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.metrics);*/
  //    //const ws2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.details);
  //    ///* generate workbook and add the worksheet */
  //    //const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //    //XLSX.utils.book_append_sheet(wb, ws1, 'Facility-Summary-Metrics');
  //    //XLSX.utils.book_append_sheet(wb, ws2, 'Facility-Summary-Details');
  //    ///* save to file */
  //    //XLSX.writeFile(wb, fileName);

  //    const CSV_TYPE = 'text/csv;charset=utf-8';
  //    const CSV_EXTENSION = '.csv';
  //    let fileName = "facility_summary_" + this.filters.facility + "_" + this.filters.month + "_" + this.filters.year + "_" + this.filters.operationalefficiency + "_" + new Date().getTime() +".csv";

  //    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.metrics);
  //    const csvOutput: string = XLSX.utils.sheet_to_csv(worksheet);
  //    saveAs(new Blob([csvOutput]), `${fileName}`);
  //  }
  //}

  GetSummaryDetails(Metric: string) {

    if (this.details != undefined) {
      var x = this.details.filter(d => d.Metric == Metric);
      if (x != undefined && x.length > 0) {
        var y;
        y = x.sort((x, y) => x.DetailIndex < y.DetailIndex ? -1 : 1);
        return y;
      }
    }
    return [];
  }
}
