import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private httpClient: HttpClient) {
  }

  /********************* Common API *****************************/
  GetMetricDescription(metric: string) {
    return this.httpClient.get<string>(environment.apiURL + "common/GetMetricDescription/" + metric);
  }
  GetAllMetrics() {
    return this.httpClient.get<any>(environment.apiURL + "common/GetAllMetrics");
  }
  GetUserMenu() {
    return this.httpClient.get<any>(environment.apiURL + "userroles/getUserMenu").toPromise();
  }
  GetRole() {
    return this.httpClient.get<any>(environment.apiURL + "userroles/getRole").toPromise();
  }
  //GetUserMenu() {
  //  return this.httpClient.get<any>(environment.apiURL + "userroles/getUserMenu");
  //}
  GetUser() {
    return this.httpClient.get<any>(environment.apiURL + "userroles/getUser").toPromise();
  }
  GetLastupdatedDate(applicationId:string) {
    return this.httpClient.get<any>(environment.apiURL + "userroles/getLastupdatedDate/" + applicationId).toPromise();
  }
  GetNotificationMessage() {
    return this.httpClient.get<any>(environment.apiURL + "userroles/getNotificationMessage");
  }
  GetUsersConfirmation(confirmationType: string) {
    return this.httpClient.get<any>(environment.apiURL + "common/GetUsersConfirmation/" + confirmationType + "/" + environment.AppId).toPromise();
  }
  SetUsersConfirmation(confirmationType: string, reporttype: string,desc:string) {
    return this.httpClient.get<any>(environment.apiURL + "common/SetUsersConfirmation/" + confirmationType + "/" + environment.AppId + "/" + reporttype + "/" + desc).toPromise();
  }
  /********************* Recomendations API *****************************/
  GetRecomendations(recomendationId: number) {
    return this.httpClient.get<any>(environment.apiURL + "recomendations/GetRecommendations/" + recomendationId);
  }
  GetAllRecommendations(facility: string, startDate: string, endDate: string, metric: string, providers: string) {
    var obj = {}
    obj['facility'] = facility;
    obj['startDate'] = startDate;
    obj['enDate'] = endDate;
    obj['metric'] = metric;
    obj['providers'] = providers;

    return this.httpClient.post<Array<any>>(environment.apiURL + "recomendations/GetAllRecommendations", obj);
  }
  PostStudies(id: number) {
    return this.httpClient.post<string>(environment.apiURL + "recomendations/PostCohort", id);
  }
  /********************* Metric Comparison API *****************************/
  GetMetricComparisonData(compType: string, facility: string, surgeService: string, fromDate: string, toDate: string, location: string) {
    return this.httpClient.get(environment.apiURL + "metriccomparison/GetMetricComparison/" + compType + "/" + facility + "/" + surgeService + "/" + fromDate + "/" + toDate + "/" + location);
  }
  GetMetricComparisonBar(facility: string, location: string, rooms: string, fromDate: string, toDate: string, metric: string, surgeService: string, staffRole: string) {
    var obj = {}
    obj['facility'] = facility;
    obj['location'] = location;
    obj['rooms'] = rooms;
    obj['surgeService'] = surgeService;
    obj['fromDate'] = fromDate;
    obj['toDate'] = toDate;
    obj['metric'] = metric;
    obj['staffRole'] = staffRole;

    return this.httpClient.post(environment.apiURL + "metriccomparison/GetMetricBar", obj);
  }


  /********************* OR Snapshot API *****************************/
  GetReportData(facility: string, location: string, rooms: string, surgeService: string, fromDate: string, toDate: string) {
    var obj = {}
    obj['facility'] = facility;
    obj['location'] = location;
    obj['rooms'] = rooms;
    obj['surgeService'] = surgeService;
    obj['fromDate'] = fromDate;
    obj['toDate'] = toDate;
    return this.httpClient.post(environment.apiURL + "orsnapshot/GetReportData", obj);
  }
  GetCaseCancelInights(facility: string, location: string, rooms: string, surgeService: string, fromDate: string, toDate: string) {
    var obj = {}
    obj['facility'] = facility;
    obj['location'] = location;
    obj['rooms'] = rooms;
    obj['surgeService'] = surgeService;
    obj['fromDate'] = fromDate;
    obj['toDate'] = toDate;
    return this.httpClient.post(environment.apiURL + "orsnapshot/GetCaseCancelInights", obj);
  }
  GetUtilInights(facility: string, location: string, rooms: string, surgeService: string, fromDate: string, toDate: string, metric: string) {
    var obj = {}
    obj['facility'] = facility;
    obj['location'] = location;
    obj['rooms'] = rooms;
    obj['surgeService'] = surgeService;
    obj['fromDate'] = fromDate;
    obj['toDate'] = toDate;
    obj['metric'] = metric;
    return this.httpClient.post<any>(environment.apiURL + "orsnapshot/GetUtilInights", obj);
  }
  GetORSnapshotInsights(facility: string, location: string, rooms: string, surgeService: string, fromDate: string, toDate: string, metric: string) {
    var obj = {}
    obj['facility'] = facility;
    obj['location'] = location;
    obj['rooms'] = rooms;
    obj['surgeService'] = surgeService;
    obj['fromDate'] = fromDate;
    obj['toDate'] = toDate;
    obj['metric'] = metric;
    return this.httpClient.post(environment.apiURL + "orsnapshot/GetInsights", obj);
  }
  GetCaseCancellation(facility: string, location: string, surgeService: string, fromDate: string, toDate: string,
    currentPage: number, recordsPerPage: number, rooms: string
    , caseNumber: string, cancelDate: string, dos: string, bucket: string, category: string, failedDetails: string, service: string, condition: string
    , cost: number, cancelreason: string, cancelcomment: string) {
    var obj = {}
    obj['facility'] = facility;
    obj['location'] = location;
    obj['rooms'] = rooms;
    obj['surgeService'] = surgeService;
    obj['fromDate'] = fromDate;
    obj['toDate'] = toDate;
    obj['currentPage'] = currentPage;
    obj['recordsPerPage'] = recordsPerPage;

    obj['cancelDate'] = cancelDate;
    obj['dos'] = dos;
    obj['caseNumber'] = caseNumber;
    obj['bucket'] = bucket;
    obj['category'] = category;
    obj['failedDetails'] = failedDetails;
    obj['service'] = service;
    obj['condition'] = condition;
    obj['cost'] = cost;
    obj['cancelreason'] = cancelreason;
    obj['cancelcomment'] = cancelcomment;

    return this.httpClient.post(environment.apiURL + "orsnapshot/GetCasecancellation", obj);
  }

  GetCaseCancellation5Day(facility: string, location: string, surgeService: string,
    fromDate: string, toDate: string, currentPage: number, recordsPerPage: number, rooms: string, caseNumber: string
    , cancelDate: string, dos: string, bucket: string, category: string, failedDetails: string,
    service: string, condition: string, cost: number, cancelreason: string, cancelcomment: string) {
    var obj = {}
    obj['facility'] = facility;
    obj['location'] = location;
    obj['rooms'] = rooms;
    obj['surgeService'] = surgeService;
    obj['fromDate'] = fromDate;
    obj['toDate'] = toDate;
    obj['currentPage'] = currentPage;
    obj['recordsPerPage'] = recordsPerPage;

    obj['cancelDate'] = cancelDate;
    obj['dos'] = dos;
    obj['caseNumber'] = caseNumber;
    obj['bucket'] = bucket;
    obj['category'] = category;
    obj['failedDetails'] = failedDetails;
    obj['service'] = service;
    obj['condition'] = condition;
    obj['cost'] = cost;
    obj['cancelreason'] = cancelreason;
    obj['cancelcomment'] = cancelcomment;
    return this.httpClient.post(environment.apiURL + "orsnapshot/GetCasecancellation5Day", obj);
  }

  GetCaseCancellation3Day(facility: string, location: string, surgeService: string,
    fromDate: string, toDate: string, currentPage: number, recordsPerPage: number, rooms: string, caseNumber: string
    , cancelDate: string, dos: string, bucket: string, category: string, failedDetails: string,
    service: string, condition: string, cost: number, cancelreason: string, cancelcomment: string) {
    var obj = {}
    obj['facility'] = facility;
    obj['location'] = location;
    obj['rooms'] = rooms;
    obj['surgeService'] = surgeService;
    obj['fromDate'] = fromDate;
    obj['toDate'] = toDate;
    obj['currentPage'] = currentPage;
    obj['recordsPerPage'] = recordsPerPage;

    obj['cancelDate'] = cancelDate;
    obj['dos'] = dos;
    obj['caseNumber'] = caseNumber;
    obj['bucket'] = bucket;
    obj['category'] = category;
    obj['failedDetails'] = failedDetails;
    obj['service'] = service;
    obj['condition'] = condition;
    obj['cost'] = cost;
    obj['cancelreason'] = cancelreason;
    obj['cancelcomment'] = cancelcomment;
    return this.httpClient.post(environment.apiURL + "orsnapshot/GetCasecancellation3Day", obj);
  }
  GetDormancy_twoPlusSurgeons(facility: string, staffRole: string, provider: string, location: string, surgeService: string, fromDate: string, toDate: string, currentPage: number, recordsPerPage: number) {
    return this.httpClient.get(environment.apiURL + "orsnapshot/GetDormancytwoPlusSurgeons/" + facility + "/" + staffRole + "/" + provider + "/" + location + "/" + surgeService + "/" + fromDate + "/" + toDate + "/" + currentPage + "/" + recordsPerPage);
  }

  GetMetricBar(facility: string, location: string, rooms: string, fromDate: string, toDate: string, metric: string, surgeService: string, staffRole: string) {
    var obj = {}
    obj['facility'] = facility;
    obj['location'] = location;
    obj['rooms'] = rooms;
    obj['surgeService'] = surgeService;
    obj['fromDate'] = fromDate;
    obj['toDate'] = toDate;
    obj['metric'] = metric;
    obj['staffRole'] = staffRole;

    return this.httpClient.post(environment.apiURL + "orsnapshot/GetMetricBar", obj);
  }
  GetUtilizationMetricBar(location: string, fromDate: string, toDate: string, metric: string) {
    return this.httpClient.get(environment.apiURL + "orsnapshot/GetUtilizationMetricBar/" + location + "/" + fromDate + "/" + toDate + "/" + metric);
  }
  GetFailedCases(facility: string, location: string, rooms: string, surgeService: string, staffRole: string, fromDate: string, toDate: string, metric: string) {
    var obj = {}
    obj['facility'] = facility;
    obj['location'] = location;
    obj['rooms'] = rooms;
    obj['surgeService'] = surgeService;
    obj['fromDate'] = fromDate;
    obj['toDate'] = toDate;
    obj['staffRole'] = staffRole;
    obj['metric'] = metric;
    return this.httpClient.post(environment.apiURL + "orsnapshot/GetFailedCases", obj);
  }
  GetcaseDetail(metric, procid) {
    return this.httpClient.get(environment.apiURL + "orsnapshot/GetFailedCaseDetail/" + metric + "/" + procid);
  }
  GetAllcases(facility: string, location: string, rooms: string, surgeService: string, fromDate: string, toDate: string, currentPage: number, recordsPerPage: number, sortColumn: string, sortOrder: string) {
    var obj = {}
    obj['facility'] = facility;
    obj['location'] = location;
    obj['rooms'] = rooms;
    obj['surgeService'] = surgeService;
    obj['fromDate'] = fromDate;
    obj['toDate'] = toDate;
    obj['currentPage'] = currentPage;
    obj['recordsPerPage'] = recordsPerPage;
    obj['sortColumn'] = sortColumn;
    obj['sortOrder'] = sortOrder;
    return this.httpClient.post(environment.apiURL + "orsnapshot/GetAllCases", obj);
  }
  /********************* Financial Portfolio API *****************************/
  GetFinancialPortfolio(facility: string, provider: string, surgService: string, fiscalYear: string) {

    return this.httpClient.get(environment.apiURL + "financialportfolio/GetFinancialPortfolio/" + facility + "/" + provider + "/" + surgService + "/" + fiscalYear);
  }
  GetTurnoverThresholds(facility, surgeService, location) {
    return this.httpClient.get(environment.apiURL + "orsnapshot/GetTurnoverThresholds/" + facility + "/" + surgeService + "/" + location);
  }
  /********************* RUV API *****************************/
  GetRVU(facility: string, location: string, staffRole: string, provider: string, surgService: string, fromDate: string, toDate: string, currentPage: number, recordsPerPage: number, sortColumn: string, sortOrder: string, query: string) {
    var obj = {}
    obj['facility'] = facility;
    obj['location'] = location;
    obj['staffRole'] = staffRole;
    obj['provider'] = provider;
    obj['SurgService'] = surgService;
    obj['fromDate'] = fromDate;
    obj['toDate'] = toDate;
    obj['currentPage'] = currentPage;
    obj['recordsPerPage'] = recordsPerPage;
    obj['sortColumn'] = sortColumn;
    obj['sortOrder'] = sortOrder;
    obj['query'] = query;

    return this.httpClient.post<Array<any>>(environment.apiURL + "rvu/GetRVU", obj);

    //return this.httpClient.get<any>(environment.apiURL + "rvu/GetRVU/" + facility + "/" + location + "/" + staffRole + "/" + provider + "/" + surgService + "/" + fromDate + "/" + toDate + "/" + currentPage.toString() + "/" + recordsPerPage.toString() + "/" + sortColumn + "/" + sortOrder);
  }
  GetRVUSummary(facility: string, location: string, staffRole: string, provider: string, surgService: string, fromDate: string, toDate: string, currentPage: number, recordsPerPage: number, sortColumn: string, sortOrder: string, query: string) {
    var obj = {}
    obj['facility'] = facility;
    obj['location'] = location;
    obj['staffRole'] = staffRole;
    obj['provider'] = provider;
    obj['SurgService'] = surgService;
    obj['fromDate'] = fromDate;
    obj['toDate'] = toDate;
    obj['currentPage'] = currentPage;
    obj['recordsPerPage'] = recordsPerPage;
    obj['sortColumn'] = sortColumn;
    obj['sortOrder'] = sortOrder;
    obj['query'] = query;

    return this.httpClient.post<Array<any>>(environment.apiURL + "rvu/GetRVUSummary", obj);
    //return this.httpClient.get<any>(environment.apiURL + "rvu/GetRVUSummary/" + facility + "/" + location + "/" + staffRole + "/" + provider + "/" + surgService + "/" + fromDate + "/" + toDate + "/" + currentPage.toString() + "/" + recordsPerPage.toString() + "/" + sortColumn + "/" + sortOrder);
  }
  DownloadRVU(facility: string, location: string, staffRole: string, provider: string, surgService: string, fromDate: string, toDate: string, password: string) {
    let userlogin = (sessionStorage.getItem("userlogin") == null || sessionStorage.getItem("userlogin") == undefined) ? "" : sessionStorage.getItem("userlogin");
    window.open(environment.apiURL + "rvu/DownloadRVU/" + facility + "/" + location + "/" + staffRole + "/" + provider + "/" + surgService + "/" + fromDate + "/" + toDate + "/" + password + "/" + userlogin, '_blank').focus();
  }
  DownloadRVUSummary(facility: string, location: string, staffRole: string, provider: string, surgService: string, fromDate: string, toDate: string, password: string) {
    let userlogin = (sessionStorage.getItem("userlogin") == null || sessionStorage.getItem("userlogin") == undefined) ? "" : sessionStorage.getItem("userlogin");
    window.open(environment.apiURL + "rvu/DownloadRVUSummary/" + facility + "/" + location + "/" + staffRole + "/" + provider + "/" + surgService + "/" + fromDate + "/" + toDate + "/" + password + "/" + userlogin, '_blank').focus();
  }
  EmailRVU(facility: string, location: string, staffRole: string, provider: string, surgService: string, fromDate: string, toDate: string, password: string) {
    let userlogin = (sessionStorage.getItem("userlogin") == null || sessionStorage.getItem("userlogin") == undefined) ? "" : sessionStorage.getItem("userlogin");
    return this.httpClient.get<any>(environment.apiURL + "rvu/EmailRVU/" + facility + "/" + location + "/" + staffRole + "/" + provider + "/" + surgService + "/" + fromDate + "/" + toDate + "/" + password + "/" + userlogin);
  }
  EmailRVUSummary(facility: string, location: string, staffRole: string, provider: string, surgService: string, fromDate: string, toDate: string, password: string) {
    let userlogin = (sessionStorage.getItem("userlogin") == null || sessionStorage.getItem("userlogin") == undefined) ? "" : sessionStorage.getItem("userlogin");
    return this.httpClient.get<any>(environment.apiURL + "rvu/EmailRVUSummary/" + facility + "/" + location + "/" + staffRole + "/" + provider + "/" + surgService + "/" + fromDate + "/" + toDate + "/" + password + "/" + userlogin);
  }
  /********************* Patient Outcome API *****************************/
  GetPatientOutcome(facility: string, location: string, staffRole: string, surgeService: string, provider: string, fromDate: string, toDate: string) {
    return this.httpClient.get(environment.apiURL + "patientoutcome/GetPatientoutcome/" + facility + "/" + location + "/" + staffRole + "/" + surgeService + "/" + provider + "/" + fromDate + "/" + toDate);
  }
  GetPatientOutcomeAllcases(facility: string, location: string, staffRole: string, surgeService: string, provider: string, fromDate: string, toDate: string, metric: string, currentPage: number, recordsPerPage: number, sortColumn: string, sortOrder: string, searchValue: string) {

    return this.httpClient.get<string>(environment.apiURL + "patientoutcome/GetPatientoutcomeAllcases/" + facility + "/" + location + "/" + staffRole + "/" + surgeService + "/" + provider + "/" + fromDate + "/" + toDate + "/" + metric + "/" + currentPage + "/" + recordsPerPage + "/" + sortColumn + "/" + sortOrder + "/" + searchValue);
  }
  GetPatientOutcomeFailed(metric: string, procId: string) {
    return this.httpClient.get<Array<any>>(environment.apiURL + "patientoutcome/GetPatientOutcomeFailed/" + metric + "/" + procId);
  }
  /********************* Workflow API *****************************/
  GetWorkflow(facility: string, location: string, provider: string, surgService: string, caseDate: string) {
    return this.httpClient.get(environment.apiURL + "workflow/GetWorkflow/" + facility + "/" + location + "/" + provider + "/" + surgService + "/" + caseDate);
  }

  GetTwoRoomSurgeons(fromDate: string, toDate: string) {
    return this.httpClient.get(environment.apiURL + "two-room-surgeons/GetTwoRoomSurgeons/" + fromDate + "/" + toDate);
  }
  /********************* Case Mix API *****************************/
  GetCaseMix(facility: string, location: string, surgeService: string, staffRole: string, provider: string, fromDate: string, toDate: string) {
    return this.httpClient.get(environment.apiURL + "casemix/GetCaseMix/" + facility + "/" + location + "/" + surgeService + "/" + staffRole + "/" + provider + "/" + fromDate + "/" + toDate);
  }
  /********************* Case Log API *****************************/
  GetCaseLog(facility: string, location: string, surgeService: string, staffRole: string, provider: string, fromDate: string, toDate: string, currentPage: number, recordsPerPage: number, sortColumn: string, sortOrder: string) {

    return this.httpClient.get(environment.apiURL + "casemix/GetCaseLog/" + facility + "/" + location + "/" + surgeService + "/" + staffRole + "/" + provider + "/" + fromDate + "/" + toDate + "/" + currentPage.toString() + "/" + recordsPerPage.toString() + "/" + sortColumn + "/" + sortOrder);
  }
  DownloadCaseLog(facility: string, location: string, surgeService: string, staffRole: string, provider: string, fromDate: string, toDate: string, password: string) {
    let userlogin = (sessionStorage.getItem("userlogin") == null || sessionStorage.getItem("userlogin") == undefined) ? "" : sessionStorage.getItem("userlogin");
    window.open(environment.apiURL + "casemix/DownloadCaseLog/" + facility + "/" + location + "/" + surgeService + "/" + staffRole + "/" + provider + "/" + fromDate + "/" + toDate + "/" + userlogin + "/" + password, '_blank').focus();
    //return this.httpClient.get(environment.apiURL + "casemix/DownloadCaseLog/" + facility + "/" + location + "/" + staffRole + "/" + provider + "/" + fromDate + "/" + toDate );
  }
  EmailCaseLog(facility: string, location: string, surgeService: string, staffRole: string, provider: string, fromDate: string, toDate: string, password: string) {
    let userlogin = (sessionStorage.getItem("userlogin") == null || sessionStorage.getItem("userlogin") == undefined) ? "" : sessionStorage.getItem("userlogin");
    return this.httpClient.get<any>(environment.apiURL + "casemix/EmailCaseLog/" + facility + "/" + location + "/" + surgeService + "/" + staffRole + "/" + provider + "/" + fromDate + "/" + toDate + "/" + userlogin + "/" + password);
  }
  /********************* Time Stat API *****************************/
  GetTimeStat(facility: string, location: string, surgeService: string, fromDate: string, toDate: string) {
    return this.httpClient.get(environment.apiURL + "timestat/GetTimeStat/" + facility + "/" + location + "/" + surgeService + "/" + fromDate + "/" + toDate);
  }
  /*********************Metric Dashboard API *****************************/
  GetInights(facility: string, location: string, staffRole: string, surgeService: string, provider: string, fromDate: string, toDate: string, metric: string) {
    return this.httpClient.get(environment.apiURL + "metricdashboard/GetInights/" + facility + "/" + location + "/" + staffRole + "/" + surgeService + "/" + provider + "/" + fromDate + "/" + toDate + "/" + metric);
  }
  GetMetricDashboardData(facility: string, location: string, staffRole: string, surgeService: string, provider: string, fromDate: string, toDate: string) {
    return this.httpClient.get(environment.apiURL + "metricdashboard/GetReportData/" + facility + "/" + location + "/" + staffRole + "/" + surgeService + "/" + provider + "/" + fromDate + "/" + toDate);
  }
  GetMetricTimeStat(facility: string, location: string, staffRole: string, surgeService: string, provider: string, fromDate: string, toDate: string) {
    return this.httpClient.get(environment.apiURL + "metricdashboard/GetMetricTimeStat/" + facility + "/" + location + "/" + staffRole + "/" + surgeService + "/" + provider + "/" + fromDate + "/" + toDate);
  }
  GetMetricDashboardBar(facility: string, location: string, staffRole: string, surgeService: string, provider: string, fromDate: string, toDate: string, metric: string) {
    return this.httpClient.get(environment.apiURL + "metricdashboard/GetMetricDashboardBar/" + facility + "/" + location + "/" + staffRole + "/" + surgeService + "/" + provider + "/" + fromDate + "/" + toDate + "/" + metric);
  }
  GetMetricFailedCases(facility: string, location: string, staffRole: string, surgeService: string, provider: string, fromDate: string, toDate: string, metric: string) {
    return this.httpClient.get(environment.apiURL + "metricdashboard/GetMetricFailedCases/" + facility + "/" + location + "/" + staffRole + "/" + surgeService + "/" + provider + "/" + fromDate + "/" + toDate + "/" + metric);
  }
  /********************* Distribution and Rank API *****************************/
  GetDistRank(staffRole: string, facility: string, surgeService: string, fromdate: string, toDate: string) {
    return this.httpClient.get(environment.apiURL + "distrank/GetDistRank/" + staffRole + "/" + facility + "/" + surgeService + "/" + fromdate + "/" + toDate);
  }
  GetDistRankProvider(staffRole: string, facility: string, surgeService: string, provider: string, fromdate: string, toDate: string) {
    return this.httpClient.get(environment.apiURL + "distrank/GetDistRankProvider/" + staffRole + "/" + facility + "/" + surgeService + "/" + provider + "/" + fromdate + "/" + toDate);
  }
  GetDistRankBar(staffRole: string, facility: string, surgeService: string, provider: string, fromdate: string, toDate: string, metric: string, dateRange: string) {
    return this.httpClient.get(environment.apiURL + "distrank/GetDistRankBar/" + staffRole + "/" + facility + "/" + surgeService + "/" + provider + "/" + fromdate + "/" + toDate + "/" + metric + "/" + dateRange);
  }
  /********************* Provider Comparision API *****************************/
  GetProviderComparisonMetrics(metricCategory: string) {
    return this.httpClient.get(environment.apiURL + "providercomparison/GetProviderComparisonMetrics/" + metricCategory);
  }
  GetProviderComparison(facility: string, location: string, staffRole: string, surgService: string, fromDate: string, toDate: string) {
    return this.httpClient.get(environment.apiURL + "providercomparison/GetProviderComparison/" + facility + "/" + location + "/" + staffRole + "/" + surgService + "/" + fromDate + "/" + toDate);
  }
  GetProviderComparisonDownload(facility: string, location: string, staffRole: string, surgService: string, fromDate: string, toDate: string, selectedMetrics: string, password: string) {
    let userlogin = (sessionStorage.getItem("userlogin") == null || sessionStorage.getItem("userlogin") == undefined) ? "" : sessionStorage.getItem("userlogin");
    //return this.httpClient.get(environment.apiURL + "providercomparison/GetProviderComparisonDownload/" + facility + "/" + location + "/" + staffRole + "/" + surgService + "/" + fromDate + "/" + toDate + "/" + selectedMetrics);
    window.open(environment.apiURL + "providercomparison/GetProviderComparisonDownload/" + facility + "/" + location + "/" + staffRole + "/" + surgService + "/" + fromDate + "/" + toDate + "/" + selectedMetrics + "/" + userlogin + "/" + password, '_blank').focus();
  }
  GetProviderComparisonEmail(facility: string, location: string, staffRole: string, surgService: string, fromDate: string, toDate: string, selectedMetrics: string, password: string) {
    let userlogin = (sessionStorage.getItem("userlogin") == null || sessionStorage.getItem("userlogin") == undefined) ? "" : sessionStorage.getItem("userlogin");
    return this.httpClient.get(environment.apiURL + "providercomparison/GetProviderComparisonEmail/" + facility + "/" + location + "/" + staffRole + "/" + surgService + "/" + fromDate + "/" + toDate + "/" + selectedMetrics + "/" + userlogin + "/" + password);
  }
  /********************* Provider Report API *****************************/
  GetProviderComparsion(facility: string, staffRole: string, caseyear: string, casemonth: string, surgService: string, currentPage: number, recordsPerPage: number, sortColumn: string, sortOrder: string, providers: string) {
    var obj = {}
    obj['facility'] = facility;
    obj['staffRole'] = staffRole;
    obj['caseYear'] = caseyear;
    obj['caseMonth'] = casemonth;
    obj['surgService'] = surgService;
    obj['currentPage'] = currentPage;
    obj['recordsPerPage'] = recordsPerPage;
    obj['sortColumn'] = sortColumn;
    obj['sortOrder'] = sortOrder;
    obj['providers'] = providers;
    return this.httpClient.post<string>(environment.apiURL + "providerreport/GetProviderReport", obj);
  }

  DownloadProviderReportInit(facility: string, staffRole: string, caseyear: string, casemonth: string, surgService: string, currentPage: number, recordsPerPage: number, sortColumn: string, sortOrder: string, providers: string) {
    var obj = {}
    obj['facility'] = facility;
    obj['staffRole'] = staffRole;
    obj['caseYear'] = caseyear;
    obj['caseMonth'] = casemonth;
    obj['surgService'] = surgService;
    obj['currentPage'] = currentPage;
    obj['recordsPerPage'] = recordsPerPage;
    obj['sortColumn'] = sortColumn;
    obj['sortOrder'] = sortOrder;
    obj['providers'] = providers;
    return this.httpClient.post<string>(environment.apiURL + "providerreport/DownloadProviderReportInit", obj);
  }

  DownloadProviderReport(filename: string, password: string) {
    let userlogin = (sessionStorage.getItem("userlogin") == null || sessionStorage.getItem("userlogin") == undefined) ? "" : sessionStorage.getItem("userlogin");
    window.open(environment.apiURL + "providerreport/DownloadProviderReport/" + filename + "/" + userlogin + "/" + password, '_blank').focus();
    //return this.httpClient.get(environment.apiURL + "casemix/DownloadCaseLog/" + facility + "/" + location + "/" + staffRole + "/" + provider + "/" + fromDate + "/" + toDate );
  }
  EmailProviderReport(filename: string, password: string) {
    let userlogin = (sessionStorage.getItem("userlogin") == null || sessionStorage.getItem("userlogin") == undefined) ? "" : sessionStorage.getItem("userlogin");
    return this.httpClient.get(environment.apiURL + "providerreport/EmailProviderReport/" + filename + "/" + userlogin + "/" + password);
  }
  /********************* Block Utilization API *****************************/
  GetBlockRecomendations(recomendationId: number) {
    return this.httpClient.get<any>(environment.apiURL + "blockutil/GetRecommendations/" + recomendationId);
  }
  GetAllBlockRecommendations(facility: string, location: string, surgService: string, fromDate: string, toDate: string) {
    return this.httpClient.get<Array<any>>(environment.apiURL + "blockutil/GetAllRecommendations/" + facility + "/" + location + "/" + surgService + "/" + fromDate + "/" + toDate);
  }


  GetBlockData(facility: string, location: string, surgService: string, fromDate: string, toDate: string, view: string) {

    return this.httpClient.get(environment.apiURL + "blockutil/GetBlockData/" + facility + "/" + location + "/" + surgService + "/" + fromDate + "/" + toDate + "/" + view);
  }

  GetBlockSchedule(facility: string, location: string, year: string, month: string, dayofweek: string) {
    return this.httpClient.get(environment.apiURL + "blockschedule/GetBlockSchedule/" + facility + "/" + location + "/" + year + "/" + month + "/" + dayofweek);
  }

  GetBlockRequest() {
    return this.httpClient.get(environment.apiURL + "blockrequest/GetBlockRequest");
  }

  GetReport(reportType: string, dateType: string) {
    return this.httpClient.get<any>(environment.apiURL + "blockutil/GetReport/" + reportType + "/" + dateType);
  }

  /********************* Case Volume Forecast API *****************************/
  GetCaseVolumeForecast(facility: string, location: string, surgService: string) {
    return this.httpClient.get<any>(environment.apiURL + "casevolumeforecast/GetCaseVolumeForecast/" + facility + "/" + location + "/" + surgService);
  }

  /********************* Glucose Management API *****************************/
  GetGlucoseManagementData(facility: string, location: string, staffRole: string, provider: string, surgService: string, fromDate: string, toDate: string, gliflozinFilter: number, diabetesFilter: number, afterHoursFilter: number) {
    return this.httpClient.get(environment.apiURL + "glucosemanagement/GetGlucoseManagementData/" + facility + "/" + location + "/" + staffRole + "/" + provider + "/" + surgService + "/" + fromDate + "/" + toDate + "/" + gliflozinFilter + "/" + diabetesFilter + "/" + afterHoursFilter);
  }

  GetGlucoseCases(facility: string, location: string, staffRole: string, provider: string, surgService: string, fromDate: string, toDate: string, gliflozinFilter: number, diabetesFilter: number, afterHoursFilter: number, metric: string, metricValue: string) {
    return this.httpClient.get(environment.apiURL + "glucosemanagement/GetGlucoseCases/" + facility + "/" + location + "/" + staffRole + "/" + provider + "/" + surgService + "/" + fromDate + "/" + toDate + "/" + gliflozinFilter + "/" + diabetesFilter + "/" + afterHoursFilter + "/" + metric + "/" + metricValue);
  }

  GetGlucoseCaseDetails(procid: string) {
    return this.httpClient.get(environment.apiURL + "glucosemanagement/GetGlucoseCaseDetails/" + procid);
  }

  /********************* Facility Summary API *****************************/
  GetFacilitySummary(facility: string, month: string, year: string, metriccategory: string) {
    return this.httpClient.get<any>(environment.apiURL + "facilitysummary/GetFacilitiesSummary/" + facility + "/" + month + "/" + year + "/" + metriccategory);
  }

  /********************* Perivision API *****************************/
  GetPerivision(facility: string, location: string, caseDate: string) {
    return this.httpClient.get<any>(environment.apiURL + "perivision/GetPerivision/" + facility + "/" + location + "/" + caseDate);
  }

  /********************* Filter API *****************************/
  GetFacility(staffRole: string, screen: string) {
    return this.httpClient.get<Array<string>>(environment.apiURL + "filter/GetFacility/" + staffRole + "/" + screen);
  }
  GetSurgeService(facility: string, surgeService: string, screen: string, location: string) {
    return this.httpClient.get<Array<string>>(environment.apiURL + "filter/GetSurgeService/" + facility + "/" + surgeService + "/" + screen + "/" + location);
  }
  GetStaffRole() {
    return this.httpClient.get<Array<string>>(environment.apiURL + "filter/GetStaffRole");
  }
  GetProvider(facility: string, staffRole: string, surgeService: string, fromDate: string, toDate: string) {
    return this.httpClient.get<Array<any>>(environment.apiURL + "filter/GetAllProvider/" + facility + "/" + staffRole + "/" + surgeService + "/" + fromDate + "/" + toDate);
  }
  GetLocation(facility: string, staffRole: string, surgeService: string, screen: string) {
    return this.httpClient.get<Array<string>>(environment.apiURL + "filter/GetLocation/" + facility + "/" + staffRole + "/" + surgeService + "/" + screen);
  }
  GetRooms(facility: string, location: string) {
    return this.httpClient.get<Array<string>>(environment.apiURL + "filter/GetRooms/" + facility + "/" + location);
  }
  GetMetrics(fromData: string, toDate: string, facility: string) {
    return this.httpClient.get<Array<string>>(environment.apiURL + "filter/GetMetrics/" + fromData + "/" + toDate + "/" + facility);
  }
  GetFiscalYears() {
    return this.httpClient.get<Array<string>>(environment.apiURL + "filter/GetFiscalYears");
  }

  /********************* Common Login *****************************/
  PostLog(userlog: any) {
    return this.httpClient.post<any>(environment.auditLogURL + "auth/auditlog", userlog);
  }

  /********************* Common Sortine *****************************/
  Sort(data: any, col: string, sortorder: string) {
    /********************* To find column is string or number *******************************/
    let is_numeric = (str) => {
      return /^\d+$/.test(str);
    }
    let isNum = data.map(d => d[col]).filter(f => !is_numeric(f));

    /********************* To sort Date column *******************************/
    if (col == 'CaseDate') {
      if (sortorder == 'asc') {
        data = data.sort(function (obj1, obj2) {
          return new Date(obj1[col]).getTime() - new Date(obj2[col]).getTime();
        });
      }
      else {
        data = data.sort(function (obj1, obj2) {
          return new Date(obj2[col]).getTime() - new Date(obj1[col]).getTime();
        });
      }
    }
    /********************* To sort number column *******************************/
    else if (isNum.length == 0) {
      if (sortorder == 'asc') {
        data = data.sort(function (obj1, obj2) {
          return obj1[col] - obj2[col];
        });
      }
      else {
        data = data.sort(function (obj1, obj2) {
          return obj2[col] - obj1[col];
        });
      }
    }
    /********************* To sort string column *******************************/
    else {
      if (sortorder == 'asc') {
        data = data.sort(function (obj1, obj2) {
          return ('' + obj1[col]).localeCompare(obj2[col]);
        });
      }
      else {
        data = data.sort(function (obj1, obj2) {
          return ('' + obj2[col]).localeCompare(obj1[col]);
        });
      }
    }
    return data;
  }


  /********************* To Get max value *******************************/
  GetMax(arr, prop) {
    var max;
    for (var i = 0; i < arr.length; i++) {
      if (max == null || parseInt(arr[i][prop]) > parseInt(max[prop]))
        max = arr[i];
    }
    return max;
  }
}
