
<header class="navbar  navbar-light sticky-top d-print-none">
  <div class="container-xl">
    <h1 class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
      <a href=".">
        <img src="../assets/logo.PNG" width="110" height="32" alt="Tabler" class="navbar-brand-image">
      </a>
    </h1>
    <div class="navbar-nav flex-row">
      <div class="nav-item d-none d-md-flex me-3">

      </div>

      <div class="nav-item dropdown">
        <div class="nav-link d-flex lh-1 text-reset p-0">
         
          <div class="d-xl-block ps-2">
            
            <!--<div></div>-->
            <div class="mt-1 small p-0" style="color:red;font-weight:600">
               {{notificationmessage}}

            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="navbar-nav flex-row order-md-last">
      <div class="nav-item d-none d-md-flex me-3">

      </div>

      <div class="nav-item dropdown">
        <div class="nav-link d-flex lh-1 text-reset p-0">
          <span class="avatar avatar-sm" style="background-image: url(./static/avatars/000m.jpg)"></span>
          <div class="d-none d-xl-block ps-2">
            <div>
              {{user}} <a (click)="logout()" style="cursor:pointer !important">
                (Logout)
              </a>
            </div>
            <!--<div></div>-->
            <div class="mt-1 small ">
              {{lastUpdateddate}}

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</header>
<div class="container-xl  navbar-light sticky-top" style="max-width:100% !important;background: #f5f7fb;">
  <div class="row" *ngIf="isLoader==true" style=" background-color: #fff; opacity: 0.5; height: 60px; z-index: 10000; position: absolute; position: absolute; top: 0px; text-align: center; width: 97%; padding-top: 15px;">
    <div class="col-12">
      <img src="../../assets/loader.gif" style="opacity:1!important;" />
    </div>
  </div>
  <div class="row" style="padding:16px 0px 7px 0px;margin:0px auto;z-index:1000">

    <div class="row align-items-center">
      <div class="col" *ngIf="report.comptype==true">
        <div class="font-weight-medium">
          Comp Type
        </div>
        <div class="text-muted">
          <select class="form-select" [(ngModel)]="selectedCompType" (change)="ChangeCompType();">
            <option value="facility">Facility</option>
            <option value="service">Service</option>
          </select>
        </div>
      </div>

      <div class="col" *ngIf="report.casedate==true">
        <div class="font-weight-medium">
          Case Date
        </div>
        <div class="text-muted">
          <input type="text" class="form-control" [(ngModel)]="caseDate" bsDatepicker (ngModelChange)="DateChanged($event)"
                 [bsConfig]="{ isAnimated: true, dateInputFormat: 'MM/DD/YYYY' }">
        </div>
      </div>
      <div class="col" *ngIf="report.daterange==true">
        <div class="font-weight-medium">
          Date Filter
        </div>
        <div class="text-muted">
          <select class="form-select" [(ngModel)]="selectedDateRange" (change)="ChangeDateRange();FiltersChanged();">
            <option value="custom" *ngIf="reportName != 'distrank' && reportName != 'metric'">Custom Date Range</option>
            <option value="month">Month / Year</option>
            <option value="quarter">Quarter</option>
            <option value="year">Year</option>
          </select>
        </div>
      </div>
      <div class="col" *ngIf="report.customdaterange==true && report.daterange==true">
        <div class="font-weight-medium">
          Start Date
        </div>
        <div class="text-muted">
          <input type="text" class="form-control" [(ngModel)]="fromDate" bsDatepicker (ngModelChange)="DateChanged($event)"
                 [maxDate]="toDate" [bsConfig]="{ isAnimated: true, dateInputFormat: 'MM/DD/YYYY' }">
        </div>
      </div>
      <div class="col" *ngIf="report.customdaterange==true && report.daterange==true">
        <div class="font-weight-medium">
          End Date
        </div>
        <div class="text-muted">
          <input type="text" class="form-control" [(ngModel)]="toDate" bsDatepicker (ngModelChange)="DateChanged($event)"
                 [minDate]="fromDate" [bsConfig]="{ isAnimated: true, dateInputFormat: 'MM/DD/YYYY' }">
        </div>
      </div>
      <div class="col" *ngIf="report.year==true">
        <div class="font-weight-medium">
          Year
        </div>
        <div class="text-muted">
          <select class="form-select" [(ngModel)]="selectedYear" (change)="GetMonths();GetQuarters();FiltersChanged();">
            <option value="{{currentYear-1
                }}">
              {{currentYear-1}}
            </option>
            <option value="{{currentYear}}">{{currentYear}}</option>

          </select>
        </div>
      </div>
      <div class="col" *ngIf="report.quarter==true && report.daterange==true">
        <div class="font-weight-medium">
          Quarter
        </div>
        <div class="text-muted">
          <select class="form-select" [(ngModel)]="selectedQuarter" (change)="FiltersChanged();">


            <option *ngFor='let q of quarters' [value]="q.value">{{q.name}}</option>

          </select>
        </div>
      </div>
      <div class="col" *ngIf="report.month==true">
        <div class="font-weight-medium">
          Month
        </div>
        <div class="text-muted">
          <select class="form-select" [(ngModel)]="selectedMonth" (change)="FiltersChanged();">
            <option *ngFor='let m of months' [value]="m.value">{{m.name}}</option>
          </select>
        </div>
      </div>
      <div class="col" *ngIf="report.fiscalyear==true">
        <div class="font-weight-medium">
          Fiscal Year
        </div>
        <div class="text-muted">
          <select class="form-select" [(ngModel)]="selectedFiscalYear" (change)="FiltersChanged();">
            <option *ngFor='let y of fiscalYears' [value]="y">{{y}}</option>
          </select>
        </div>
      </div>
      <div class="col" *ngIf="report.staffrole==true">
        <div class="font-weight-medium">
          Staff Role
        </div>
        <div class="text-muted">

          <select class="form-select" (change)="GetFacilities();ChangeStaffRole();FiltersChanged();"
                  [(ngModel)]="selectedStaffRole">
            <option *ngFor='let s of staffRoles' [value]="s">{{s}}</option>
          </select>
        </div>
      </div>
      <div class="col" *ngIf="report.facility==true">
        <div class="font-weight-medium">
          Facility
        </div>
        <div class="text-muted">

          <select class="form-select" [(ngModel)]="selectedFacility"
                  (change)="GetLocation();FiltersChanged();GetMetrics();">
            <option *ngFor='let f of facilities' [value]="f">{{f}}</option>
          </select>
        </div>
      </div>

      <div class="col" *ngIf="report.operationalefficiency==true">
        <div class="font-weight-medium">
          Operational Efficiency
        </div>
        <div class="text-muted">
          <select class="form-select" [(ngModel)]="selectedOperationalEfficiency" (change)="FiltersChanged();">
            <option *ngFor='let o of operationalEfficiency' [value]="o.value">{{o.name}}</option>
          </select>
        </div>
      </div>
      <div class="col" *ngIf="report.location==true">
        <div class="font-weight-medium">
          Location
        </div>
        <div class="text-muted">

          <select class="form-select" [(ngModel)]="selectedLocation" (change)="GetRooms();GetSurgeServices();FiltersChanged();">
            <option *ngFor='let f of locations' [value]="f">{{f}}</option>
          </select>
        </div>
      </div>

      <div class="col" *ngIf="report.metric==true && report.metric==true">
        <div class="font-weight-medium">
          Metric
        </div>
        <div class="text-muted">
          <select class="form-select" [(ngModel)]="selectedMetric">
            <option *ngFor='let m of metrics' [value]="m.Metric">{{m.MetricName}}</option>
          </select>
        </div>
      </div>



      <div class="col" *ngIf="report.surgeservice==true">
        <div class="font-weight-medium">
          Surgical Service
        </div>
        <div class="text-muted">
          <select class="form-select" (change)="GetProvider();FiltersChanged();" [(ngModel)]="selectedSurgeService">
            <option *ngFor='let s of surgeServices' [value]="s">{{s}}</option>
          </select>
        </div>
      </div>

      <div class="col" *ngIf="report.room==true">
        <div class="font-weight-medium">
          Room
        </div>
        <div class="text-muted">

          <ng-multiselect-dropdown [placeholder]="'Select Room'"
                                   [settings]="roomMultiSettings"
                                   [data]="rooms"
                                   [(ngModel)]="selectedRooms">

          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="col" *ngIf="report.provider==true">
        <div class="font-weight-medium">
          Provider
        </div>
        <div class="text-muted">
          <ng-select [(ngModel)]="selectedProvider">
            <ng-option *ngFor="let p of providers" [value]="p.UserLogin">{{p.StaffName}}</ng-option>
          </ng-select>
        </div>
      </div>
      <div class="col-3" *ngIf="report.multiprovider==true" style="min-width:300px !important">
        <div class="font-weight-medium">
          <span>Provider </span><span style="float:right">
            <input class="form-check-input" type="checkbox" [(ngModel)]="isSelectAll" (change)="SelectAll();FiltersChanged();">&nbsp;
            <span>All</span>
          </span>
        </div>
        <div class="text-muted">
          <ng-multiselect-dropdown [placeholder]="'Select Providers'"
                                   [settings]="providerMultiSettings"
                                   [data]="providers"
                                   [(ngModel)]="selectedMultiProvider"
                                   [disabled]="isSelectAll">

          </ng-multiselect-dropdown>


        </div>
      </div>
      <div class="col">
        <div class="font-weight-medium">
          &nbsp;
        </div>
        <div class="text-muted">
          <button type="button" class="btn btn-primary" (click)="OnFilter()">
            Apply
          </button>
        </div>
      </div>

      <div class="col" *ngIf="report.operationalefficiency==true">
        <div class="font-weight-medium">
          &nbsp;
        </div>
        <div class="text-muted">
          <a title="Download" (click)="OnDownload()" class="downloadbtn">
            <img src="../../assets/downloadpng.png" />
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="row" style="padding-bottom:3px;">
    <div class="col" *ngIf="isChanged==true" style="color:red;text-align:center;width:90%;">
      Filter conditions have changed - the previous data may be incorrect. Please click 'Apply' to update the dashboard.
    </div>
  </div>
</div>
