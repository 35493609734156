export class Filters {
  public facility: string;
  public operationalefficiency: string;
  public surgeService: string;
  public staffRole: string;
  public provider: string;
  public providerName: string;
  public fromDate: string;
  public toDate: string;
  public month: string;
  public year: string;
  public quarter: string;
  public fiscalYear: string;

  public location: string;
  public dateRange: string;
  public caseDate: string;
  public multiprovider: Array<string>;
  public rooms: Array<string>;
  public isProviderSelectAll: boolean;
  public isRoomSelectAll: boolean;
  public isSelectAll: boolean;
  public multiproviderObject: Array<any>;
  public providerObj: any;
  public metric: string;
  public comptype: string;
  public tableSwitch: boolean = true;


  public riskcount: number;
  public riskfactor: string;
  public durationgroup: string;
  public procedureType: string;
  public nauseaDeduction: string;
  public prophylaxis: string;
  public painscore: string;
  public metricnew: string;

  public gender: string;
  public agegroup: string;
  public asa: string;
  public dispositiontype: string;
  

  public filterObject: string;

  //public unFormatfromDate: string;
  //public unFormattoDate: string;
}
